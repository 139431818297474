import React, { useState } from "react";
import "../Styles/secdule.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Flag from "./Flag";
import NewFaq from "./NewFaq";

const TimeSecdule = () => {
  const [SelectedData, onChange] = useState(new Date());
  const [TimeSelection, SetTimeSelection] = useState();
  const [Meeting, SetMeeting] = useState();
  console.log(Meeting);
  console.log(TimeSelection);
  console.log(SelectedData);

  return (
    <>
      <div className="container-fluid Hero-ection-banner-bg">
        <div className="container main-contenr">
          <h1 className="main-head-hero-section">Schedule A Demo</h1>
          <p className="new-para-main-hero-section">
            Revolutionize Your Workflow: Request a Personalized Software Demo
          </p>
        </div>
      </div>

      <div className="container form-new-01"  style={{marginBottom:'380px'}}>
        <h2 className="from-head">Thanks For Reaching Out</h2>
        <h4 className="new-great">HAVE A GREAT DAY!</h4>
        <p className="new-apra-manin">
          To us, your time is an extremely valuable asset.
        </p>
        <p className="new-main-90">
          Please take a moment to find the most appropriate time for us to
          connect.Need help? Please write us to sales@askaritechnologies.com
        </p>
        <div className="calender-section-09">
          <div className="calender-90">
            <div>
              <h1 className="calnder-head">Shedule A Call</h1>
              <div>
                <Calendar onChange={onChange} SelectedData={SelectedData} />
              </div>
              <p>Selected Date: {SelectedData.toLocaleDateString()}</p>
            </div>
          </div>
          <div className="calender-content">
            <div className="calender-content-heading">
              <h3 className="calender-time">How long do you need?</h3>
              <div className="time-secdules-btns">
                <button
                  value={15}
                  className="min-15"
                  onClick={(e) => SetTimeSelection(e.target.value)}
                >
                  15 min
                </button>
                <button
                  value={30}
                  className="min-30"
                  onClick={(e) => SetTimeSelection(e.target.value)}
                >
                  30 min
                </button>
              </div>
              <h3 className="work-best-time">What time works best?</h3>
              <p className="utc-time">UTC -07:00 Pacific Time</p>
            </div>
            <div className="time-manage-btn">
              <button
                className="time-secletin-01"
                value={9}
                onClick={(e) => SetMeeting(e.target.value)}
              >
                10:00 am
              </button>
              <button
                className="time-secletin-01"
                value={10}
                onClick={(e) => SetMeeting(e.target.value)}
              >
                10:00 am
              </button>
              <button
                className="time-secletin-01"
                value={11}
                onClick={(e) => SetMeeting(e.target.value)}
              >
                10:00 am
              </button>
              <button
                className="time-secletin-01"
                value={12}
                onClick={(e) => SetMeeting(e.target.value)}
              >
                10:00 am
              </button>
              <button
                className="time-secletin-01"
                value={4}
                onClick={(e) => SetMeeting(e.target.value)}
              >
                10:00 am
              </button>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </>
  );
};

export default TimeSecdule;
