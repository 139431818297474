import React, { useState,useEffect } from 'react';
import axios from 'axios';
import "../Styles/Contact.css";


function Contactsub() {
  const [currentURL, setCurrentURL] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    // Get the current URL and set it as the input field's value
    const url = window.location.href;
    setCurrentURL(url);
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      message: message,
      url: currentURL
    };
console.log(phoneNumber,currentURL)
    axios.post('YOUR_API_ENDPOINT_HERE', formData)
      .then(response => {
        console.log('Form submission successful:', response.data);
        // You can add any additional actions you want to perform after successful submission
      })
      .catch(error => {
        console.error('Form submission error:', error);
      });
  };
  return (
    <section className="contact-us" id="contact-us" >
    <div className="container-fluid2 ">
      <div className="row">
        <div className="col-lg-6 conact-c1 formpad px-md-5  ml-8" style={{minHeight:'350px'}} >
          <h4 className="contact-head ">
            Get 30-Minute <br></br> Free Strategy<br></br>  Consultation
          </h4>
          <p className="contact-para">
            Let’s discuss how Askari  can help you convert <br></br> breakthrough
            ideas into tangible outcomes. <br></br>Take the first step with our
            software product <br></br> engineering services today!
          </p>
        </div>
        <div className="col-lg-6 conact-c2" style={{paddingRight:'30px',paddingLeft:'30px'}}>
          <h1 className="conatact-tittle ">Get Started</h1>

          <form className='form' onSubmit={handleSubmit} >
            <div >
              <label for="exampleInputName" class="form-label" className="198 Boring Lane" style={{marginBottom:'6px'}}>
                Name
              </label>
              <input
                type="name"
                class="form-control"
                id="exampleInputname"
                aria-describedby="nameHelp"
                placeholder="Enter Your Name"
                className="input_pro_eng"
                value={name}
            onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div >
              <label for="exampleInputName" class="form-label" className="align_contact_lab" style={{marginBottom:'6px'}}>
                Email
              </label>
              <input
                type="email"
                class="form-control"
                id="exampleInputname"
                aria-describedby="nameHelp"
                placeholder="Enter Your Email"
                className="input_pro_eng"
                value={email}
            onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div >
              <label for="exampleInpuEmail" class="form-label" className="align_contact_lab" style={{marginBottom:'6px'}}>
                Phone Number
              </label>
              <input
                type="number   "
                class="form-control"
                id="exampleInputEmail"
                placeholder="Enter Your Phone Number"
                className="input_pro_eng"
                value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div >
              <label for="exampleFormControlTextarea1" class="form-label" className="align_contact_lab" style={{marginBottom:'6px'}}>
                Message
              </label>
              <textarea
                
                class="form-control"
                id="exampleInputEmail"
                placeholder="Give us a brief about your requirment"
                className="input_pro_eng_inp"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button type="submit" class="btn_get_form">
              Get Started
            </button>
            <p >
            
              <span className="guaranteed" > 100% Guaranteed </span> Security
              of the Information.
            </p>
            <div>
      <input type="text" value={currentURL} readOnly  style={{display:'none'}}/>
    </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Contactsub
