import React from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import sol2 from "../Assets/sol2pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import Card11 from "../Assets/cardimg1.png";
import Card21 from "../Assets/Electro.png";
import Card31 from "../Assets/garmentsa.png";
import Card41 from "../Assets/beautypro.png";
import Card51 from "../Assets/supplies.png";
import Card61 from "../Assets/pharmaD.png";
import Card71 from "../Assets/wholesale.png";
import Card81 from "../Assets/beverage.png";
import Card91 from "../Assets/consumer.png";
import NewFaq from "./NewFaq";
function Solution2() {
  return (
    <div style={{overflow:'hidden'}}>
      <div className="banner_Solution2">
        <div className="Solutionbanner1_section">
          <h2>Streamlined Sales Management and Order Booking</h2>
          <p>
            Instant Sales, a cutting-edge Salesforce software developed by our
            company, offers a comprehensive solution for efficient sales
            management and streamlined order booking processes. With its
            advanced features and user-friendly interfaces, Instant Sales
            empowers businesses to optimize their sales operations, enhance
            productivity, and drive revenue growth.
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              Empowering Efficient Sales Management and Streamlined Order
              Booking Processes
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              That sounds impressive! Instant Sales, your cutting-edge
              Salesforce software, seems to provide businesses with a robust
              solution for sales management and order booking processes. The
              combination of advanced features and user-friendly interfaces can
              indeed help businesses optimize their sales operations and drive
              revenue growth.
            </p>
          </div>
          <div className="section2_img">
            <img src={sol2} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            Features Of Sales Management
          </h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Efficient Order Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                nstant Sales ensures streamlined order processing and seamless
                coordination between salesmen, distributors, and manufacturers.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Real-time Sales Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Gain valuable insights from comprehensive data analysis to
                enhance decision-making and drive sales growth.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intuitive Mobile Application
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Experience a user-friendly mobile app that simplifies order
                booking and provides a seamless sales process.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Customizable Dashboards
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                Access personalized dashboards to monitor sales performance,
                track orders, and manage inventory effortlessly.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Secure Data Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Instant Sales prioritizes data security and confidentiality,
                ensuring the protection of sensitive sales-related information.
                Benefits:
              </p>
            </div>

            <div className="btn_sol1">
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section
        style={{
          backgroundColor: "#F5F5F5",
          paddingTop: "0px",
          paddingBottom: "30px",
         marginTop:'5%'
        }}
      >
        <div className="container ppp" style={{ maxWidth: "1000px" }}>
          <h3
            style={{
              color: "#3D4DAE",
              fontSize: "40px",
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              marginLeft: "20px",
             paddingTop:'30px',
              marginBottom: "40px",
            }}
          >
            Benefits of ClientConnect
          </h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew22">
                <img src={Some} style={{ marginBottom: "15px" }} />
                <h4>Streamlined Order Processing</h4>
                <p>
                  Instant Sales simplifies the order management process, saving
                  time and effort for sales teams, distributors, and
                  manufacturers.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew22">
                <img src={Some2} style={{ marginBottom: "15px" }} />
                <h4>Data-Driven Decision Making</h4>
                <p>
                Gain valuable insights from sales analytics to make informed business decisions and drive sales growth.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some6} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Sales Efficiency</h4>
                <p>
                The intuitive mobile application improves sales efficiency by providing a seamless and user-friendly experience for order booking.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some3} style={{ marginBottom: "15px" }} />
                <h4>Improved Sales Performance</h4>
                <p>
                Access customizable dashboards to monitor sales performance, track orders, and optimize inventory management for enhanced sales results.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some4} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Data Security</h4>
                <p>
                Instant Sales prioritizes data security, ensuring the confidentiality and integrity of sales-related information.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some5} style={{ marginBottom: "15px" }} />
                <h4>Increased Sales Productivity</h4>
                <p>
                Instant Sales boosts sales team productivity by simplifying the order management process, allowing them to focus on generating revenue and closing deals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
      <div className="container" style={{maxWidth:'1060px',marginTop:'6%'}} >
      <h3 style={{color:'#3D4DAE' ,fontSize:"40px",fontWeight:'700',marginLeft:'2%'}}>Industries and How ClientConnect Helps Them</h3>
      <p style={{color:'#686868',fontSize:'20px',marginLeft:'2%'}}>E-commerce: ClientConnect enables personalized engagement and targeted marketing campaigns, helping e-commerce businesses improve customer satisfaction and increase conversions.</p>
        <div className="row" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
          <div className="col " style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
            <div className="cardsec">
              <img src={Card71} />
              <div style={{textAlign:'center',padding:'12px'}}>
                <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Wholesale Distribution</h4>
                <p style={{color:'#686868',fontSize:'15px'}}> 
                Instant Sales revolutionizes shop-to-shop sales for wholesale distributors by providing a streamlined and efficient order management system.
                </p>
              </div>
            </div>
          </div>
          <div className="col" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>  <div className="cardsec">
          <img src={Card91} />
          <div style={{textAlign:'center',padding:'12px'}}>
            <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Consumer Goods</h4>
            <p style={{color:'#686868',fontSize:'15px'}}> 
            Instant Sales empowers consumer goods companies engaged in shop-to-shop sales by optimizing their sales processes.  
            </p>
          </div>
        </div></div>
          <div className="col" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>  <div className="cardsec">
          <img src={Card81} />
          <div style={{textAlign:'center',padding:'12px'}}>
            <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Beverage and Food Services</h4>
            <p style={{color:'#686868',fontSize:'15px'}}> 
            For businesses in the beverage and food services industry, Instant Sales enhances their shop-to-shop sales operations. It enables sales representatives to effectively manage inventory, take orders.
            </p>
          </div>
        </div></div>
        </div>
        <div className="row" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
        <div className="col " style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
          <div className="cardsec">
            <img src={Card61} />
            <div style={{textAlign:'center',padding:'12px'}}>
              <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Pharmaceutical Distribution</h4>
              <p style={{color:'#686868',fontSize:'15px'}}> 
              Instant Sales revolutionizes the shop-to-shop sales process for pharmaceutical distributors. It streamlines order management, ensures accurate inventory tracking.
              </p>
            </div>
          </div>
        </div>
        <div className="col" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>  <div className="cardsec">
        <img src={Card51} />
        <div style={{textAlign:'center',padding:'12px'}}>
          <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Office Supplies</h4>
          <p style={{color:'#686868',fontSize:'15px'}}> 
          Instant Sales simplifies shop-to-shop sales for office supply businesses by providing an intuitive and efficient order management system. It enables sales representatives to manage orders, track inventory.
          </p>
        </div>
      </div></div>
        <div className="col" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>  <div className="cardsec">
        <img src={Card41} />
        <div style={{textAlign:'center',padding:'12px'}}>
          <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Beauty and Cosmetics</h4>
          <p style={{color:'#686868',fontSize:'15px'}}> 
          Instant Sales enhances the shop-to-shop sales experience for beauty and cosmetics companies. It provides sales representatives with a user-friendly interface to manage product catalogs, process orders..
          </p>
        </div>
      </div></div>
      </div>
      <div className="row" style={{display:'flex', alignItems:'center',justifyContent:'center' }}>
      <div className="col " style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
        <div className="cardsec">
          <img src={Card31} />
          <div style={{textAlign:'center',padding:'12px'}}>
            <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Garments and Apparel</h4>
            <p style={{color:'#686868',fontSize:'15px'}}> 
            or businesses in the garments and apparel industry, Instant Sales streamlines the shop-to-shop sales process. It enables sales representatives to showcase product catalogs, process orders.
            </p>
          </div>
        </div>
      </div>
      <div className="col" style={{display:'flex', alignItems:'center',justifyContent:'center'}}>  <div className="cardsec">
      <img src={Card11} />
      <div style={{textAlign:'center',padding:'12px'}}>
        <h4 style={{color:'#3D4DAE' ,fontSize:"24px",fontWeight:'700'}}>Electronics</h4>
        <p style={{color:'#686868',fontSize:'15px'}}> 
        Instant Sales transforms shop-to-shop sales operations for electronics businesses. It provides sales representatives with a comprehensive platform to manage product portfolios, handle order placement
        </p>
      </div>
    </div></div>
      
    </div>
      </div>
    </section>

      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 ">
          <div className="text-center">
            <h3 className="text-askari-primary fw-bold">
              Empowering Businesses with Streamlined Sales Processes and
              Real-Time Order Tracking
            </h3>
            <p className="mt-3" style={{color:'white'}}>
              Instant Sales is a versatile and user-friendly Salesforce software
              that empowers businesses to streamline their sales processes,
              track orders in real-time, and enhance overall sales management.
              With its mobile and web-based applications, customizable
              dashboards, and advanced features, Instant Sales enables
              businesses to achieve sales excellence and maximize their growth
              potential.
            </p>
          </div>
        </div>
      </div>
      <Flag/>
      <NewFaq />
    </div>
  );
}

export default Solution2;
