import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Faq from "./Faq";
import Hidemob from "../Assets/pix.png";
const SalesImplemation = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images2">
        <div className="container p-4 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-8 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Salesforce Implementation Services
              </h1>
              <p className="main-hero-para-main-div">
                Sales Cloud Implementation Elevate your sales performance,
                enhance customer communication, and gain valuable insights into
                sales pipelines and forecasting with our Salesforce Sales Cloud
                implementation services.
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation{" "}
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  Why Choose Us for SFDC Implementation
                </h2>
                <p className="about-main-content-parap">
                  When it comes to Salesforce CRM implementation, partnering
                  with our team offers a unique blend of technical expertise and
                  business acumen, ensuring that we deliver the ideal solution
                  to optimize and propel your business forward. With extensive
                  experience in CRM and diverse Salesforce modules such as Sales
                  Cloud, Service Cloud, Communities, and Field Service Cloud, we
                  guide you through a seamless implementation process and
                  maximize the value you derive from Salesforce.<br></br>{" "}
                  <br></br> As a trusted Salesforce ISV partner and a leading
                  Salesforce implementation company, we offer a comprehensive
                  range of services, including consulting, configuration,
                  development, migration, support, and maintenance. Our focus is
                  on developing a tailored solution that aligns precisely with
                  your specific requirements. Our flexible engagement model
                  empowers you to scale your project as needed, while
                  maintaining complete control over its direction. Moreover, our
                  developers are Salesforce certified, ensuring top-notch
                  expertise and a mutually beneficial partnership.<br></br>{" "}
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5">
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head py-2">
              Choose our Salesforce implementation team to design the CRM{" "}
              <br></br>
            </h1>

            <div className="col-md-4 col-sm-8 card-main-div-main-card ">
              <p className="card-top-main">Sales Cloud Implementation</p>
              <p className="main-top-bottom-content">
                Elevate your sales performance, enhance customer communication,
                and gain valuable insights into sales pipelines and forecasting
                with our Salesforce Sales Cloud implementation services.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Service Cloud Implementation</p>
              <p className="main-top-bottom-content">
                Drive customer loyalty by streamlining workflows and delivering
                personalized services across every customer touchpoint. Our
                Salesforce Service Cloud implementation consultants offer
                expertise in optimizing customer interactions.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Community Cloud Implementation</p>
              <p className="main-top-bottom-content">
                Create cutting-edge communities that reflect your brand and
                engage customers, partners, and employees in real-time
                experiences. Leverage our Salesforce Community Cloud
                implementation services to build customized community platforms.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce for NonProfits</p>
              <p className="main-top-bottom-content">
                Empower your nonprofit organization with Salesforce for
                Nonprofits, enabling digital transformation and creating a
                lasting impact. Our Salesforce implementation consultants
                specialize in purpose-driven solutions to manage income streams,
                grants, and programs in a unified space.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              Advantages of Salesforce Implementation
            </h1>
            <p
              className="main-services-para-main-card-top py-3"
              style={{ color: "white" }}
            ></p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Enhanced Data Accuracy
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    By implementing Salesforce, you can centralize customer
                    data, eliminating scattered information across departments
                    and ensuring data accuracy and consistency.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Streamlined Data Access from Multiple Sources
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Salesforce implementation allows integration with various
                    tools like Oracle and SAP, enabling you to access data from
                    different sources. With powerful APIs, you can create a
                    comprehensive view of customers and their interactions.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Sales Forecasting</p>
                  <p className="arricval-card-content-main-last-top">
                    Salesforce implementation provides insights into past and
                    present sales trends, enabling accurate sales forecasting.
                    This helps in identifying opportunities and planning future
                    campaigns to drive revenue growth.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Improved and Automated Workflows
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Salesforce implementation optimizes business processes,
                    facilitating streamlined workflows and efficient product
                    delivery. It simplifies sales goal achievement, eliminating
                    unnecessary complexities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Discover How Askari Technologies Can Assist With Salesforce
                  Implementation
                </h2>
                <p className="h2-h3-h4">
                  Irrespective of the project's scale, Askari Technologies
                  empowers you to seize opportunities by providing an extensive
                  array of Salesforce implementation services and delivering
                  business advantages.
                </p>
                <ul className="newul">
                  <li>Strategic Outlook</li>
                  <li>Profound Industry Knowledge</li>

                  <li>
                    Committed Consultants for Salesforce CRM Implementation
                  </li>
                  <li>A Group of Accredited Salesforce Developers</li>
                  <li>Customer-Focused Approach</li>
                  <li>Timely Project Completion</li>
                  <li>Competitive Pricing Structure</li>
                  <li>Ongoing Maintenance and Support</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Why is Salesforce implementation important for my
                  organization?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  Salesforce implementation provides a structured approach to
                  set up and configure Salesforce based on your business needs,
                  ensuring optimal utilization and maximum benefits.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  {" "}
                  Does your company have certified Salesforce implementation
                  consultants?{" "}
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we have certified Salesforce implementation consultants who
                possess expertise in configuring and customizing Salesforce to
                meet your specific requirements. Hire our certified consultants
                for seamless implementation.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What is the difference between Salesforce implementation and
                  Salesforce Development?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Salesforce implementation focuses on setting up and configuring
                Salesforce to align with your business processes, while
                Salesforce development involves customizing and building new
                functionalities within the Salesforce platform.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Which industries do you cater to with Salesforce
                  implementation services?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We cater to a wide range of industries, including healthcare,
                finance, manufacturing, retail, and more, providing tailored
                Salesforce implementation services to meet industry-specific
                needs.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What methodologies does your company follow for Salesforce
                  implementation?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We follow a structured approach and best practices for
                Salesforce implementation, leveraging methodologies like Agile
                and Waterfall, ensuring efficient project management and
                successful implementation.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What other services does Askari Technologies offer in
                  Salesforce Implementation?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                In addition to Salesforce implementation, we offer services such
                as data migration, system integration, customization, user
                training, ongoing support, and maintenance, providing end-to-end
                solutions for your Salesforce implementation journey.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default SalesImplemation;
