import React, { useEffect, useRef, useState } from "react";
import "../Styles/cont.css";
import contactImg from "../Assets/Image.png";
import Meeting from "../Assets/meetingcont.png";
import Circle1 from "../Assets/circle1.png";
import Circle2 from "../Assets/circle2.png";
import Flag from "./Flag";
import Rangea from "./range";
import RangeD from "./range";
import MultiRangeSlider from "./MultiRangeSlider";
import TypewriterPlaceholder from "./Extra";
import NewSlider from "./newRangeSlide";
import NewFaq from "./NewFaq";
import axios from 'axios';
const Contact = () => {
  const [start1, setStart1] = useState(10);
  const [start2, setStart2] = useState(90);

  const thumb1Ref = useRef();
  const thumb2Ref = useRef();
  const trackRef = useRef();
  const rangeRef = useRef();

  const handleDragStart = (setStart, otherStart, isDraggingThumb1) => (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;

    const updatePosition = (moveEvent) => {
      const moveClientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      let newLeft = ((moveClientX - trackRef.current.getBoundingClientRect().left) / trackRef.current.offsetWidth) * 100;
      if (newLeft < 0) newLeft = 0;
      if (isDraggingThumb1 && newLeft > otherStart) newLeft = otherStart;
      if (!isDraggingThumb1 && newLeft < otherStart) newLeft = otherStart;
      if (!isDraggingThumb1 && newLeft > 100) newLeft = 100;
      setStart(newLeft);
    };

    document.addEventListener('mousemove', updatePosition);
    document.addEventListener('touchmove', updatePosition);

    const stopDragging = () => {
      document.removeEventListener('mousemove', updatePosition);
      document.removeEventListener('touchmove', updatePosition);
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchend', stopDragging);
    };

    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('touchend', stopDragging);
  };

  useEffect(() => {
    thumb1Ref.current.style.left = `${start1}%`;
    thumb2Ref.current.style.left = `${start2}%`;
    rangeRef.current.style.left = `${start1}%`;
    rangeRef.current.style.width = `${start2 - start1}%`;
  }, [start1, start2]);

  // --------------
  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  const [inputValue3, setInputValue3] = useState('');
 
  const [inputValue4, setInputValue4] = useState('');
 

  const [inputValue5, setInputValue5] = useState('');
  

  const [inputValue6, setInputValue6] = useState('');
 
  const [inputValue7, setInputValue7] = useState('');
  
  const [isChecked, setIsChecked] = useState(false);

 

  const handleSubmit = async (event) => {
    event.preventDefault();
console.log(inputValue2);
console.log(isChecked)

console.log(start2)
    try {
      const response = await axios.post('http://localhost:5000/login', {
        inputValue,
        inputValue2,
        inputValue3,
        inputValue4,
        inputValue5,
        inputValue6,
        inputValue7,
        isChecked,
        start1,
        start2
      });

      console.log('Response:', response.data);

      // Handle successful response
    } catch (error) {
      console.error("data not found");

      // Handle error
    }
  };

  // --------------
  const checkScroll = () => {
    const section = document.getElementById('section0');
    const circle = section.querySelector('.circle');
    const rect = section.getBoundingClientRect();
    const heightThreshold = section.offsetHeight * 0.35;

    if (rect.top <= window.innerHeight - heightThreshold && rect.bottom >= heightThreshold) {
      circle.classList.add('animate');
    } else {
      circle.classList.remove('animate');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    checkScroll(); // Check on initial load
    return () => {
      window.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const checkScrollUpdated = () => {
    const targetSection = document.getElementById('section2');
    const targetCircle = targetSection.querySelector('.circle2');
    const sectionRect = targetSection.getBoundingClientRect();
    const heightThreshold = targetSection.offsetHeight * 0.35;
  
    if (sectionRect.top <= window.innerHeight - heightThreshold && sectionRect.bottom >= heightThreshold) {
      targetCircle.classList.add('animate');
    } else {
      targetCircle.classList.remove('animate');
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', checkScrollUpdated);
    window.addEventListener('resize', checkScrollUpdated);
    checkScrollUpdated(); // Check on initial load
  
    return () => {
      window.removeEventListener('scroll', checkScrollUpdated);
      window.removeEventListener('resize', checkScrollUpdated);
    };
  }, []);
  
  // ----------------
  const inputRef = useRef(null);
  const placeholderText = "Type your First Name...";
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [scrollCount, setScrollCount] = useState(0);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [isClassAdded1, setIsClassAdded1] = useState(false);

  const handleAddClass = () => {
    setIsClassAdded(true);
  };

  const handleAddClass1 = () => {
    setIsClassAdded1(true);
  };

  const handleRemoveClass = () => {
    setIsClassAdded(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Detect scrolling direction
      const isScrollingUp = window.scrollY < scrollCount;
      setScrollCount(window.scrollY);

      if (isScrollingUp) {
        if (scrollCount <= 750 && isClassAdded) {
          handleAddClass();

          setTimeout(() => {
            handleRemoveClass();
            handleAddClass1();
          }, 4000);
        }
      } else {
        if (scrollCount >= 550 && !isClassAdded) {
          handleAddClass();
          setTimeout(() => {
            handleRemoveClass();
            handleAddClass1();
          }, 4000);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollCount, isClassAdded]);

  // fbghbdfg
  const [scrollCount2, setScrollCount2] = useState(0);

  const [isClassAdded2, setIsClassAdded2] = useState(false);
  const [isClassAdded12, setIsClassAdded12] = useState(false);

  const handleAddClass2 = () => {
    setIsClassAdded2(true);
  };

  const handleAddClass12 = () => {
    setIsClassAdded12(true);
  };

  const handleRemoveClass2 = () => {
    setIsClassAdded2(false);
  };

  useEffect(() => {
    const handleScroll2 = () => {
      // Detect scrolling direction
      const isScrollingUp = window.scrollY < scrollCount2;
      setScrollCount2(window.scrollY);

      if (isScrollingUp) {
        if (scrollCount <= 800 && isClassAdded) {
          handleAddClass2();

          setTimeout(() => {
            handleRemoveClass2();
            handleAddClass12();
          }, 3000);
        }
      } else {
        if (scrollCount >= 1200 && !isClassAdded) {
          handleAddClass2();
          setTimeout(() => {
            handleRemoveClass2();
            handleAddClass12();
          }, 4000);
        }
      }
    };

    window.addEventListener("scroll", handleScroll2);

    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, [scrollCount2, isClassAdded2]);

  // ------------


  useEffect(() => {
    if (isTyping) {
      const typingInterval = setInterval(() => {
        if (currentIndex === placeholderText.length) {
          setCurrentIndex(0); // Reset index when the animation completes
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex, isTyping]);

  const handleInputClick = () => {
    setIsTyping(false);
  };

  const handleInputChange = () => {
    setIsTyping(false);
  };

  const handleInputFocus = () => {
    setIsTyping(true);
  };

  const handleInputBlur = () => {
    setIsTyping(false);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping(true);
    }
  };

  const inputRef1 = useRef(null);
  const placeholderText1 = "Type your Last Name...";
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [isTyping1, setIsTyping1] = useState(true);

  useEffect(() => {
    if (isTyping1) {
      const typingInterval = setInterval(() => {
        if (currentIndex1 === placeholderText1.length) {
          setCurrentIndex1(0); // Reset index when the animation completes
        } else {
          setCurrentIndex1((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex1, isTyping1]);

  const handleInputClick1 = () => {
    setIsTyping1(false);
  };

  const handleInputChange1 = () => {
    setIsTyping1(false);
  };

  const handleInputFocus1 = () => {
    setIsTyping1(true);
  };

  const handleInputBlur1 = () => {
    setIsTyping1(false);
  };

  const handleInputKeyDown1 = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping1(true);
    }
  };

  const inputRef2 = useRef(null);
  const placeholderText2 = "Type your Email...";
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [isTyping2, setIsTyping2] = useState(true);

  useEffect(() => {
    if (isTyping2) {
      const typingInterval = setInterval(() => {
        if (currentIndex2 === placeholderText2.length) {
          setCurrentIndex2(0); // Reset index when the animation completes
        } else {
          setCurrentIndex2((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex2, isTyping2]);

  const handleInputClick2 = () => {
    setIsTyping2(false);
  };

  const handleInputChange2 = () => {
    setIsTyping1(false);
  };

  const handleInputFocus2 = () => {
    setIsTyping1(true);
  };

  const handleInputBlur2 = () => {
    setIsTyping1(false);
  };

  const handleInputKeyDown2 = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping1(true);
    }
  };

  const inputRef3 = useRef(null);
  const placeholderText3 = "Type your Number";
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [isTyping3, setIsTyping3] = useState(true);

  useEffect(() => {
    if (isTyping3) {
      const typingInterval = setInterval(() => {
        if (currentIndex3 === placeholderText3.length) {
          setCurrentIndex3(0); // Reset index when the animation completes
        } else {
          setCurrentIndex3((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex3, isTyping3]);

  const handleInputClick3 = () => {
    setIsTyping3(false);
  };

  const handleInputChange3 = () => {
    setIsTyping3(false);
  };

  const handleInputFocus3 = () => {
    setIsTyping3(true);
  };

  const handleInputBlur3 = () => {
    setIsTyping3(false);
  };

  const handleInputKeyDown3 = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping3(true);
    }
  };

  const inputRef4 = useRef(null);
  const placeholderText4 = "Type your Company Name...";
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [isTyping4, setIsTyping4] = useState(true);

  useEffect(() => {
    if (isTyping4) {
      const typingInterval = setInterval(() => {
        if (currentIndex4 === placeholderText4.length) {
          setCurrentIndex4(0); // Reset index when the animation completes
        } else {
          setCurrentIndex4((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex4, isTyping4]);

  const handleInputClick4 = () => {
    setIsTyping(false);
  };

  const handleInputChange4 = () => {
    setIsTyping(false);
  };

  const handleInputFocus4 = () => {
    setIsTyping(true);
  };

  const handleInputBlur4 = () => {
    setIsTyping(false);
  };

  const handleInputKeyDown4 = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping(true);
    }
  };

  const inputRef5 = useRef(null);
  const placeholderText5 = "Type your Website URL...";
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [isTyping5, setIsTyping5] = useState(true);

  useEffect(() => {
    if (isTyping5) {
      const typingInterval = setInterval(() => {
        if (currentIndex5 === placeholderText5.length) {
          setCurrentIndex5(0); // Reset index when the animation completes
        } else {
          setCurrentIndex5((prevIndex) => prevIndex + 1);
        }
      }, 160);

      return () => clearInterval(typingInterval);
    }
  }, [currentIndex5, isTyping5]);

  const handleInputClick5 = () => {
    setIsTyping5(false);
  };

  const handleInputChange5 = () => {
    setIsTyping5(false);
  };

  const handleInputFocus5 = () => {
    setIsTyping5(true);
  };

  const handleInputBlur5 = () => {
    setIsTyping5(false);
  };

  const handleInputKeyDown5 = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setIsTyping5(true);
    }
  };

  const rangeInput = document.querySelectorAll(".range-input input"),
    priceInput = document.querySelectorAll(".price-input input"),
    range = document.querySelector(".slider .progress");
  let priceGap = 1000;

  priceInput.forEach((input) => {
    input.addEventListener("input", (e) => {
      let minPrice = parseInt(priceInput[0].value),
        maxPrice = parseInt(priceInput[1].value);

      if (maxPrice - minPrice >= priceGap && maxPrice <= rangeInput[1].max) {
        if (e.target.className === "input-min") {
          rangeInput[0].value = minPrice;
          range.style.left = (minPrice / rangeInput[0].max) * 100 + "%";
        } else {
          rangeInput[1].value = maxPrice;
          range.style.right = 100 - (maxPrice / rangeInput[1].max) * 100 + "%";
        }
      }
    });
  });

  rangeInput.forEach((input) => {
    input.addEventListener("input", (e) => {
      let minVal = parseInt(rangeInput[0].value),
        maxVal = parseInt(rangeInput[1].value);

      if (maxVal - minVal < priceGap) {
        if (e.target.className === "range-min") {
          rangeInput[0].value = maxVal - priceGap;
        } else {
          rangeInput[1].value = minVal + priceGap;
        }
      } else {
        priceInput[0].value = minVal;
        priceInput[1].value = maxVal;
        range.style.left = (minVal / rangeInput[0].max) * 100 + "%";
        range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
      }
    });
  });
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="ourpatners_baner1 ">
        <div className="container conact-2 ">
          <div className="col-lg-6">
            <h2 className="contact-head-2">Contact Us</h2>
            <p className="contact-para-2">
              Let's create something awosome together.
            </p>
          </div>
        </div>
      </div>
      <section className="">
        <div> </div>
        <div className="container">
          <h3 className="Howdoes">How does our process work?</h3>
          <form
          onSubmit={handleSubmit}
          >
            <div className="container contline">
              <div>
                
                  <div >
                    <div ></div>
                    <div >
                 
                   
                    <div className={isClassAdded1 ? "" : ""}>
                    <div className={isClassAdded ? "" : ""}>
                      <div className="circleside " style={{ zIndex: "4" }}>
                        <img src={Circle1} className="circleimg" />{" "}
                        <h4 className="letsstart">
                          Let’s start by understanding you
                        </h4>
                      </div>
                      <div  className="section" id="section0">
                      <div  className="circle circle0"></div>
                      <div className="text section1">


                      <div className="row centerCont  " style={{textAlign:'left'}}>
                        <div className="col">
                          <h5 className="Howdoes">Understanding You</h5>
                          <p>
                            Our friendly team would love to hear from you.
                          </p>
                          <div className="row ">
                            <div className="col">
                              <div class="mb-3 labelcustom">
                                <label
                                  for="formGroupExampleInput"
                                  class=" "
                                  
                                >
                                  First Name*
                                </label>

                                <input
                                value={inputValue} 
                                onChange={(e) => setInputValue(e.target.value)}
                                  type="text"
                                  class="form-control"
                                  id="formGroupExampleInput"
                                  placeholder={
                                    isTyping
                                      ? placeholderText.substring(
                                          0,
                                          currentIndex
                                        )
                                      : placeholderText
                                  }
                                  ref={inputRef}
                                  onClick={handleInputClick}
                                  onInput={handleInputChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  onKeyDown={handleInputKeyDown}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div class="mb-3 labelcustom">
                                <label
                                  for="formGroupExampleInput2"
                                  class=""
                                >
                                  Last Name
                                </label>
                                <input
                                value={inputValue2}
                                onChange={(e) => setInputValue2(e.target.value)} 
                                  type="text"
                                  class="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder={
                                    isTyping1
                                      ? placeholderText1.substring(
                                          0,
                                          currentIndex1
                                        )
                                      : placeholderText1
                                  }
                                  ref={inputRef1}
                                  onClick={handleInputClick1}
                                  onInput={handleInputChange1}
                                  onFocus={handleInputFocus1}
                                  onBlur={handleInputBlur1}
                                  onKeyDown={handleInputKeyDown1}
                                />
                              </div>
                            </div>
                            <div class="mb-3 labelcustom">
                              <label
                                for="exampleFormControlInput1"
                                class=" "
                              >
                                Email address
                              </label>
                              <input
                              value={inputValue3} 
                              onChange={(e) => setInputValue3(e.target.value)}
                                type="email"
                                class="form-control"
                                id="exampleFormControlInput1"
                                placeholder={
                                  isTyping2
                                    ? placeholderText2.substring(
                                        0,
                                        currentIndex2
                                      )
                                    : placeholderText2
                                }
                                ref={inputRef2}
                                onClick={handleInputClick2}
                                onInput={handleInputChange2}
                                onFocus={handleInputFocus2}
                                onBlur={handleInputBlur2}
                                onKeyDown={handleInputKeyDown2}
                              />
                              <div>{/* Your circle content here */}</div>
                            </div>
                            <div className="row">
                              <div class="mb-3 labelcustom">
                                <label
                                  for="exampleFormControlInput1"
                                  class=""
                                >
                                  Phone number
                                </label>
                              </div>

                              <div className="labelcustom">
                                <input
                                value={inputValue4} 
                                onChange={(e) => setInputValue4(e.target.value)}
                                  type="number"
                                  class="form-control"
                                  aria-label="City"
                                  placeholder={
                                    isTyping3
                                      ? placeholderText3.substring(
                                          0,
                                          currentIndex3
                                        )
                                      : placeholderText3
                                  }
                                  ref={inputRef3}
                                  onClick={handleInputClick3}
                                  onInput={handleInputChange3}
                                  onFocus={handleInputFocus3}
                                  onBlur={handleInputBlur3}
                                  onKeyDown={handleInputKeyDown3}
                                />
                              </div>

                              <div
                                class="mb-3  "
                                style={{
                                  fontSize: "16px",
                                  marginTop: "12px",
                                }}
                              >
                                <label
                                  for="formGroupExampleInput2"
                                  class=""
                                >
                                  Company Name
                                </label>
                                <input
                                value={inputValue5} 
                                onChange={(e) => setInputValue5(e.target.value)}
                                  type="text"
                                  class="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder={
                                    isTyping4
                                      ? placeholderText4.substring(
                                          0,
                                          currentIndex4
                                        )
                                      : placeholderText4
                                  }
                                  ref={inputRef4}
                                  onClick={handleInputClick4}
                                  onInput={handleInputChange4}
                                  onFocus={handleInputFocus4}
                                  onBlur={handleInputBlur4}
                                  onKeyDown={handleInputKeyDown4}
                                />
                              </div>
                              <div class="mb-3 labelcustom">
                                <label
                                  for="formGroupExampleInput2"
                                  class=""
                                >
                                  Website URL
                                </label>
                                <input
                                value={inputValue6} 
                                onChange={(e) => setInputValue6(e.target.value)}
                                  type="text"
                                  class="form-control"
                                  id="formGroupExampleInput2"
                                  placeholder={
                                    isTyping5
                                      ? placeholderText5.substring(
                                          0,
                                          currentIndex5
                                        )
                                      : placeholderText5
                                  }
                                  ref={inputRef5}
                                  onClick={handleInputClick5}
                                  onInput={handleInputChange5}
                                  onFocus={handleInputFocus5}
                                  onBlur={handleInputBlur5}
                                  onKeyDown={handleInputKeyDown5}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col hidecolimg">
                          <img
                            src={contactImg}
                            alt=""
                            className="img-fluid py-5"
                          />
                        </div>
                      </div>
                      
                     
                     
                    </div>
                  </div>
                    </div>
                  </div>
                     
                    </div>
                  </div>
             
              </div>
             
              <div className={isClassAdded1 ? "" : ""}>
                <div className={isClassAdded2 ? "" : ""}>
                  <div className="circleside ">
                    <img src={Circle2} className="circleimg" />{" "}
                    <h4 className="letsstart">What’s your requirement?</h4>
                  </div>
                  <div  className="section2" id="section2">
                  <div  className="circle circle2"></div>
                  <div className="text section1">
                 
                   <div className="row centerCont">
                    <div className="col">
                      <h5 className="Howdoes">Requirements</h5>
                      <p>Our friendly team would love to hear from you.</p>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          class="labelcustom"
                        >
                          Describe Your Project in brief*{" "}
                        </label>
                        <textarea
                        value={inputValue7} 
                        onChange={(e) => setInputValue7(e.target.value)}
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                      <div>
                       
                      <div >
                      <div className="slider1">
                        <div className="track1" ref={trackRef}>
                          <div className="range1" ref={rangeRef}></div>
                          <div className="value1" style={{left: '10%'}}>${Math.round((start1 * 1000 + 1000) / 1000) * 1000}</div>
                          <div className="value1" style={{right: '10%'}}>${Math.round((start2 * 1000 + 1000) / 1000) * 1000}</div>
                          <div id="thumb1" className="thumb1" ref={thumb1Ref} onMouseDown={handleDragStart(setStart1, start2, true)} onTouchStart={handleDragStart(setStart1, start2, true)}></div>
                          <div id="thumb2" className="thumb1" ref={thumb2Ref} onMouseDown={handleDragStart(setStart2, start1, false)} onTouchStart={handleDragStart(setStart2, start1, false)}></div>
                        </div>
                        
                      </div>
                      <div className="row" >
                      <div className="col cols1">
                        <h5>100$</h5>
                      </div>
                      <div className="col cols2">
                        <h5>100000$</h5>
                      </div>
                    </div>
                      </div>
                      </div>

                      <div
                        class=""
                        style={{
                          display: "flex ",
                          flexDirection: "row",
                          paddingBottom: "60px",
                        }}
                      >
                        <input className="" type="checkbox" value="" id="" 
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        />
                        <label
                          className=""
                          for=""
                          style={{ marginLeft: "5PX" }}
                        >
                          You agree to our friendly privacy policy.
                        </label>
                      </div>
                      <button type="submit" class="btn btmcontact" >
                        Send message
                      </button>
                      {/* <RangeD/> */}
                    </div>
                    <div className="col hidecolimg">
                      <img src={Meeting} alt="" className="img-fluid py-5" />
                    </div>
                  </div>
                  </div>
                </div>


                 
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      
      <Flag />
      <NewFaq/>
    </div>
  );
};

export default Contact;
