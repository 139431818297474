import React, { useEffect,useState } from "react";
import $ from "jquery";
import "../Styles/NewFaq.css";
import "../Styles/Hire.css";
import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/uk.png";
import UAE from "../Assets/uae.png";

const NewFaq = () => {
  
    const [activeIndex, setActiveIndex] = useState(null);
  
    const onItemClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };
  return (
    <div className="accnone">
   
    <div className="accordion">
    <div className="accordion-item">
      <div
        className={`accordion-header ${activeIndex === 0 ? 'active' : ''}`}
        onClick={() => onItemClick(0)}
      >
        <span className="ukt">  <img src={require("../Assets/uk.png")} style={{height:'22px',marginRight:'20px'}}/>United Kingdom</span>
        <span className="accordion-toggle">{activeIndex === 0 ? '-' : '+'}</span>
      </div>
      {activeIndex === 0 && (
        <div className="accordion-content">
          <p>31 St Clements Mansions Lillie Road
          London SW6 7PG</p>
        </div>
      )}
    </div>
    <div className="accordion-item">
      <div
        className={`accordion-header ${activeIndex === 1 ? 'active' : ''}`}
        onClick={() => onItemClick(1)}
      >
        <span className="ukt">  <img src={require("../Assets/aus.png")}  style={{marginRight:'20px'}}/>Australia</span>
        <span className="accordion-toggle">{activeIndex === 1 ? '-' : '+'}</span>
      </div>
      {activeIndex === 1 && (
        <div className="accordion-content">
          <p>Comming Soon      </p>
        </div>
      )}
    </div>
    {/* Add two more accordion items */}
    <div className="accordion-item">
      <div
        className={`accordion-header ${activeIndex === 2 ? 'active' : ''}`}
        onClick={() => onItemClick(2)}
      >
        <span className="ukt">   <img src={require("../Assets/pak.png")} style={{marginRight:'20px'}} />Pakistan</span>
        <span className="accordion-toggle">{activeIndex === 2 ? '-' : '+'}</span>
      </div>
      {activeIndex === 2 && (
        <div className="accordion-content">
          <p>C-20 Block 5 Gulshan-e-Iqbal,
          Karachi,Pakistan</p>
        </div>
      )}
    </div>
    <div className="accordion-item">
      <div
        className={`accordion-header ${activeIndex === 3 ? 'active' : ''}`}
        onClick={() => onItemClick(3)}
      >
        <span className="ukt">  <img src={require("../Assets/uae.png")} style={{marginRight:'20px'}} />UAE</span>
        <span className="accordion-toggle">{activeIndex === 3 ? '-' : '+'}</span>
      </div>
      {activeIndex === 3 && (
        <div className="accordion-content">
          <p>Comming Soon</p>
        </div>
      )}
    </div>
  </div>
    </div>
  );
};

export default NewFaq;
