import React, { useState } from "react";
import "../Styles/Mobtabs.css";
import { Link } from "react-router-dom";
const Tab1Content = () => (
  <div>
    <div className="container2" >
      <div className="row5 row-cols-2 ml-20">
        <div className="col-md-2 col-sm-6 tabcol">
          <img src={require("../Assets/saleforce.png")} />
        </div>
     <div className="col-md-2 col-sm-6 tabcol">
     <Link to="/dynamic365">   <img src={require("../Assets/dynamics365.png")} />
     </Link>    </div> 
        <div className="col-md-2 col-sm-6 tabcol" >
        <Link to="/net"><img src={require("../Assets/.net.png")} />
        </Link></div>
        <div className="col-md-2 col-sm-6 tabcol"  >
          <img src={require("../Assets/js.png")} />
        </div>
        <div className="col-md-2 col-sm-6 tabcol">
        <Link to="/odoo"> <img src={require("../Assets/odoo.png")} /></Link>
        </div>
        <div className="col-md-2 col-sm-6 tabcol">
          <img src={require("../Assets/php.png")} />
        </div>
      
      </div>
      <div className="row5 row-cols-2">
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/presta.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/woocommerce.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/image 25.jpg")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/sugarcrm.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/cakephp.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/html.png")} />
      </div>
    
    </div>
    <div className="row5 row-cols-2">
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/suitcrp.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/electron.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/amp.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/pwa.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/symfony.png")} />
      </div>
      <div className="col-md-2 col-sm-6 tabcol">
        <img src={require("../Assets/gsap.png")} />
      </div>
    
    </div>
    </div>
  </div>
);

const Tab2Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2">
    <div className="col-md-2 col-sm-6 tabcol ">
      <img src={require("../Assets/shopify.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/wix.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/square.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/saleforce.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/webflow.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/shopware.png")} />
    </div>
  
  </div>


</div>
  </div>
);

// Add more custom tab components as needed
const Tab3Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2 ">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/andoid.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/ios.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/fluter.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/react.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/iconic.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/kotlin.png")} />
    </div>
  
  </div>
  <div className="row5 row-cols-2">
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/swift.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/cl.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/xamarin.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/window.png")} />
  </div>
 
 

</div>

</div>
  </div>
);
const Tab4Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2 ">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/ai1.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/ps.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/ae.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/xd.png")} />
    </div> 
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/figma.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/orangeeye.png")} />
    </div>
  
  </div>
  <div className="row5 row-cols-2">
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/blue.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/pink.png")} />
  </div>
 
 
 

</div>

</div>
  </div>
);
const Tab5Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/asp.net.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/dynamics365.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol" >
      <img src={require("../Assets/.net.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol"  >
      <img src={require("../Assets/powerbi.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/azure.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/wcf.png")} />
    </div>
  
  </div>
  <div className="row5 row-cols-2">
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/vb.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/c#.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/microsoftdynamics.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/formarketing.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/fieldservice.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/netmvc.png")} />
  </div>

</div>

</div>
  </div>
);
const Tab6Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/hubspot.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/twilio.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol" >
      <img src={require("../Assets/google.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol"  >
      <img src={require("../Assets/fbcol.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/insta.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/twitter1.png")} />
    </div>
  
  </div>
  <div className="row5 row-cols-2">
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/hiq.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/creditcard.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/sagepay.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/paypal.png")} />
  </div>
  

</div>

</div>
  </div>
);
const Tab7Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2 ">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/mysql.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/mongo.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/sqql.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/entity.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/postgre.png")} />
    </div>
   
  
  </div>


</div>
  </div>
);
const Tab8Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2 ">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/selenium.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/jmeter.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/katalon.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/cypress.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/testcomplete.png")} />
    </div>
   
    <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/owasp.png")} />
  </div>
  </div>


</div>
  </div>
);
const Tab9Content = () => (
  <div>
  <div className="container" >
  <div className="row5 row-cols-2">
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/aws.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/voicec.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol" >
      <img src={require("../Assets/devops.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol"  >
      <img src={require("../Assets/docker.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/ecs.png")} />
    </div>
    <div className="col-md-2 col-sm-6 tabcol">
      <img src={require("../Assets/swarm.png")} />
    </div>
  
  </div>
  <div className="row5 row-cols-2">
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/kubernetes.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/server.png")} />
  </div>
  <div className="col-md-2 col-sm-6 tabcol">
    <img src={require("../Assets/cloud.png")} />
  </div>
  
  

</div>

</div>
  </div>
);

const tabOptions = [
  { id: 1, title: "Open Source", content: <Tab1Content /> },
  { id: 2, title: "Close Source", content: <Tab2Content /> },
  { id: 3, title: "Mobile", content: <Tab3Content /> },
  { id: 4, title: "Graphic Design", content: <Tab4Content /> },
  { id: 5, title: "Microsoft", content: <Tab5Content /> },
  { id: 6, title: "Integrations", content: <Tab6Content /> },
  { id: 7, title: "Database", content: <Tab7Content /> },
  { id: 8, title: "Testing and Automation", content: <Tab8Content /> },
  { id: 9, title: "Advanced Technologies", content: <Tab9Content /> },
  // Add more tab options as needed
];

const Mobtabs = () => {
  const [activeTab, setActiveTab] = useState(tabOptions[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="App2">
    
      <div className="tab-container">
      <div style={{textAlign:'center'}}>
      <h2 className="techhead">Technologies We Work On</h2>
      <p className="phead">
      We employ top-tier tools, technologies, and contemporary methods to keep you ahead of competitors.</p>
      </div>

        <div className="tab-scrollable">
          <div className="tab" style={{ width: "300px", display: "flex" }}>
            {tabOptions.map((tab) => (
              <button
                key={tab.id}
                className={`tab-btn ${tab.id === activeTab ? "active" : ""}`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </div>
        <div className="tab-content">
          {tabOptions.map((tab) => (
            <div
              key={tab.id}
              className={`tab-pane ${tab.id === activeTab ? "active" : ""}`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mobtabs;
