import React from "react";
import "../Styles/HireMain.css";
import MainFirst from "../Assets/openCart1.png";
import mainAbout from "../Assets/openCart2.png";
import Flexible from "../Assets/Flexible Hours.png";
import HireHour from "../Assets/Hire Team.png";
import Dedicated from "../Assets/Dedicated-Resource.png";
import Faq from "./Faq";

// ABBas

import Note from "../Assets/funtion.png";
import CV from "../Assets/Group.png";
import Meeting from "../Assets/Group.png";
import Avatar from "../Assets/avatar.png";
import Remote from "../Assets/monitor.png";
import Vector from "../Assets/Vector3.png";

import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/ame.png";
import UAE from "../Assets/uae.png";
import Fashions from "../Assets/pic1.png";
import Retail from "../Assets/pic3.png";
import AutoParts from "../Assets/autoparts.png";
import Fitness from "../Assets/apple.png";
import Furniture from "../Assets/pic5.png";
import Monitor from "../Assets/pic4.png";
import Beauty from "../Assets/beauty.png";
import Pharmaceutical from "../Assets/pharma.png";
import Rental from "../Assets/car.png";
import Banner from "../Assets/banner.png";

import pic1 from "../Assets/pic1.png";

import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";

import check from "../Assets/check.png";

import mainleft from "../Assets/mainleftimg.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";

import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import HireCompo from "./HireCompo";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import Flag from "./Flag";
import NewFaq from "./NewFaq";

const openCart = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="main-her-9009 p-4">
        <div className="container maind-v- vovo">
          <div className="row p-2">
            <div className="col-lg-6">
              <h2 className="main9080">
                Hire Professional OpenCart Developers
              </h2>
              <p className="main-908">
                Take your online store to new heights by hiring professional
                OpenCart developers from Askari Technologies. Build feature-rich
                and customizable websites using the expertise of our skilled
                OpenCart developers. Whether you need minor tweaks, seamless
                integrations, or complete migration, our dedicated OpenCart
                developers are ready to assist you.
              </p>
              <HashLink to={"#contact-us"}>
                <button className="firt-main-btn">
                  Hire OpenCart Developers
                </button>
              </HashLink>
            </div>
            <div className="col-lg-6">
              <img src={MainFirst} alt="" className="img-fluid  " />
            </div>
          </div>
        </div>
      </section>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Partner with Seasoned OpenCart Website Developers
                </h2>
                <p className="about-main-content-parap">
                  At Askari Technologies, we have a team of seasoned OpenCart
                  experts who excel in creating scalable and visually appealing
                  websites for businesses of all sizes. With their extensive
                  knowledge and expertise gained over years of experience, our
                  OpenCart developers can cater to your specific needs. From
                  enhancing website functionalities to customizing OpenCart
                  themes, they ensure your project is tailored to perfection.
                  <br></br> <br></br>Our OpenCart experts at Askari Technologies
                  are committed to delivering exceptional web development
                  solutions using industry best practices. Following an agile
                  methodology, we prioritize feedback, testing, and iterative
                  development to ensure your project's success. <br></br>{" "}
                  <br></br>
                  When you hire professional OpenCart developers from Askari
                  Technologies, you can trust them to understand your
                  requirements and transform them into high-performing online
                  stores within your budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <center>
              <h1 className="main-serv-ces-h1-head">
                Ways to Hire Our OpenCart Specialists:
              </h1>
            </center>

            <div className="col-md-2 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">OpenCart ECommerce Development</p>
              <p className="main-top-bottom-content">
                Leverage the power of OpenCart's robust features to build
                dynamic and user-friendly ecommerce websites. Our skilled
                OpenCart developers can create visually appealing designs and
                seamless user experiences for your online store.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Custom OpenCart Development</p>
              <p className="main-top-bottom-content">
                Hire OpenCart developers to create custom solutions tailored to
                your unique business requirements. Our developers specialize in
                developing custom modules, extensions, and functionalities to
                enhance the capabilities of your OpenCart website.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">OpenCart Theme Development</p>
              <p className="main-top-bottom-content">
                Our developers are recognized for their expertise in OpenCart
                theme development. By hiring OpenCart experts from Askari
                Technologies, you can have custom themes designed to enhance the
                visual appeal and user experience of your online store.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">OpenCart Module Development</p>
              <p className="main-top-bottom-content">
                Extend the functionality of your OpenCart website by hiring
                developers who excel in module development. Our experts can
                create custom modules or customize existing ones to meet your
                specific business needs.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">OpenCart Migration Services</p>
              <p className="main-top-bottom-content">
                Seamlessly migrate your existing ecommerce store to OpenCart
                with the help of our OpenCart experts. They ensure a smooth
                transition, data integrity, and optimal performance during the
                migration process.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="main-div-cli-lic">
        <div className="container ">
          <div className="row" style={{ marginBottom: "30px" }}>
            <center>
              <h3 className="new-head-phy pl-2">
                Ways to Hire Our OpenCart Specialists
              </h3>
            </center>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px",display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}
            >
              <img src={Flexible} alt="" className="img-fluid p-5" />
              <p className="hour ">Flexible Hours</p>
              <p className="new-hour-para">
                Opt for hourly hiring of OpenCart experts from Askari
                Technologies. With this model, you pay for the time and
                resources dedicated to your project. Enjoy the flexibility of
                weekly billing and retain 100% source code authorization.
              </p>
            </div>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px" ,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}
            >
              <img src={HireHour} alt="" className="img-fluid p-5" />
              <p className="hour ">Dedicated Resource</p>
              <p className="new-hour-para">
                Hire dedicated OpenCart developers on a monthly basis for
                creating tailored solutions. This model guarantees you 160 hours
                of dedicated development time based on your flexibility and
                timezone, along with direct access to our resources.
              </p>
            </div>

            <div
              className="col-lg-4 col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px" ,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }}
            >
              <img src={Dedicated} alt="" className="img-fluid p-5" />
              <p className="hour ">Hire Team</p>
              <p className="new-hour-para">
                Build your team of OpenCart developers, designers, testers, and
                engineers by selecting from our pool of skilled professionals at
                Askari Technologies. Engage with your team via email, Skype,
                Google Meet, or Zoom, and actively participate in the agile
                development process.
              </p>
            </div>
          </div>
        </div>
      </section>

      <HireCompo Title="Opencart" />

      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>

        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose Askari Tecghnologie for Hiring OpenCart Developers?
                </h2>
                <p className="h2-h3-h4">
                  With our OpenCart web app development services, you sign up
                  for quality development, timely delivery, secure services, and
                  affordable costs. We boast a team of developers who have
                  mastered working with the OpenCart platform and can deliver
                  high-revenue eCommerce sites.
                </p>
                <ul className="newul">
                  <li>Skilled OpenCart developers</li>
                  <li>Proven Experience in eCommerce vertical</li>

                  <li>100% Source Code Authorization</li>
                  <li>Flexible Engagement Models</li>
                  <li>Timely Project Delivery</li>
                  <li>Dynamic Development</li>
                  <li>Diverse Experience</li>
                  <li>Confidentiality and Integrity</li>
                  <li>Timely Project Delivery</li>
                  <li>Round-the-clock Technical Support</li>
                  <li>Error-free Solutions</li>
                  <li>Streamlined Project Management</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What is the cost to hire professional OpenCart developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  The cost of hiring professional OpenCart developers varies
                  depending on the complexity and scope of your project. Factors
                  such as customization requirements, project duration, and
                  developer expertise can influence the cost. Contact us for a
                  personalized quote based on your specific needs.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What skills should OpenCart developers possess?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                OpenCart developers should have expertise in PHP, MySQL, HTML,
                CSS, JavaScript, and the OpenCart framework. They should be
                familiar with developing and customizing themes, modules, and
                extensions in OpenCart to create tailored e-commerce solutions.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your OpenCart developers create custom themes and
                  extensions?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, our professional OpenCart developers are skilled in
                creating custom themes and extensions for OpenCart. Whether you
                need a unique design for your online store or custom
                functionality, our team can deliver tailored solutions to meet
                your requirements.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Do you provide ongoing support and maintenance for OpenCart
                  websites?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we offer comprehensive support and maintenance services for
                OpenCart websites. Our team ensures that your OpenCart site
                remains secure, up to date, and performs optimally. We provide
                regular updates, bug fixes, and ongoing assistance to ensure
                your online store runs smoothly.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Can I hire dedicated OpenCart developers on an hourly basis?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Certainly! We offer flexible hiring models, including hourly
                rates, to cater to your project needs. Whether you require
                dedicated developers for specific tasks, ongoing support, or
                full project development, you can hire our experienced OpenCart
                developers.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your OpenCart developers optimize the performance of my
                  online store?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, our OpenCart developers have expertise in optimizing the
                performance of OpenCart websites. We analyze your website's
                performance, identify bottlenecks, and implement optimization
                techniques to enhance speed, responsiveness, and user
                experience.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default openCart;
