import React from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import sol5 from "../Assets/sol5pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import Card1 from "../Assets/cardimg1.png";
import Card2 from "../Assets/card2.png";
import Card3 from "../Assets/card3.png";
import Card4 from "../Assets/card4.png";
import Card5 from "../Assets/card5.png";
import Card6 from "../Assets/card6.png";
import Card7 from "../Assets/card7.png";
import Card8 from "../Assets/card8.png";
import Card9 from "../Assets/card9.png";
import NewFaq from "./NewFaq";
function Solution5() {
  return (
    <div style={{overflow:'hidden'}}>
      <div className="banner_Solution5">
        <div className="Solutionbanner1_section">
          <h2>Empowering Agriculture with Innovative Solutions</h2>
          <p>
            AgroGenius is a comprehensive agricultural management software
            designed to revolutionize the way farmers and livestock managers
            operate. With its wide range of features and user-friendly
            interface, AgroGenius streamlines agricultural processes, maximizes
            productivity, and ensures sustainable growth in the industry. From
            crop management to livestock monitoring,
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              Empowering Efficient Sales Management and Streamlined Order
              Booking Processes
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              AgroGenius provides farmers with the tools they need to make
              informed decisions and stay ahead of the competition.
            </p>
          </div>
          <div className="section2_img">
            <img src={sol5} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
            }}
          >
            Features AgroGenius
          </h2>
          <div className="card_container_solu1">
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Crop Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                AgroGenius offers a robust set of features for effective crop
                management. Farmers can easily map their fields, track crop
                growth stages, manage seed and fertilizer usage, implement pest
                and weed control measures, and plan harvest activities. With
                detailed insights and recommendations, AgroGenius enables
                farmers to optimize crop production and maximize yields.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Livestock Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                AgroGenius simplifies the management of livestock by providing
                comprehensive tools for monitoring and maintaining animal health
                records, managing feed and nutrition plans, tracking breeding
                and reproduction cycles, and monitoring milk production. By
                streamlining these essential tasks, AgroGenius helps farmers
                improve animal welfare and enhance overall livestock
                productivity.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Equipment and Resource Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                AgroGenius simplifies the management of agricultural equipment
                and resources. Farmers can track maintenance schedules, manage
                resource allocation, and streamline inventory management. By
                ensuring efficient utilization of equipment and resources,
                AgroGenius helps reduce downtime, minimize costs, and improve
                operational efficiency.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Weather Monitoring and Forecasting
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                }}
              >
                AgroGenius integrates real-time weather monitoring and
                forecasting capabilities, providing farmers with accurate and
                localized weather information. This empowers farmers to make
                informed decisions regarding irrigation, planting, spraying, and
                other critical agricultural activities. By leveraging precise
                weather data, AgroGenius helps optimize resource utilization and
                minimize weather-related risks.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Reporting and Analytics
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                AgroGenius offers robust reporting and analytics features,
                allowing farmers to visualize and analyze their agricultural
                data. Farmers can generate customizable reports, access
                performance analytics, and create interactive dashboards. By
                leveraging data-driven insights, AgroGenius enables farmers to
                identify trends, make informed decisions, and drive continuous
                improvement in their farming operations.
              </p>
            </div>
            <div className="sub_card_sol1">
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Financial Management
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                AgroGenius includes robust financial management capabilities
                tailored specifically for the agriculture industry. Farmers can
                create budgets, track expenses, manage income and sales data,
                and perform profitability analysis. By providing real-time
                financial insights, AgroGenius enables farmers to make informed
                decisions, optimize resource allocation, and achieve financial
                success.
              </p>
            </div>

            <div className="btn_sol1">
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>

      <section
      style={{
        backgroundColor: "#F5F5F5",
        paddingTop: "0px",
        paddingBottom: "30px",
       marginTop:'5%'
      }}
    className="">
      <div className="container ppp" style={{ maxWidth: "1200px" }}>
        <h3
          style={{
            color: "#3D4DAE",
            fontSize: "48px",
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
            marginLeft: "20px",
           paddingTop:'30px',
            marginBottom: "40px",
          }}
        >
        Benefits of AgroGenius
        </h3>

        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            className="col-md-6 col-sm-12 "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some} style={{ marginBottom: "15px" }} />
              <h4>Increased Crop Yield</h4>
              <p>
              AgroGenius employs AI-powered analytics to optimize crop management practices, resulting in increased crop yields. By leveraging data-driven insights and recommendations, farmers can make informed decisions about irrigation, fertilization etc.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some2} style={{ marginBottom: "15px" }} />
              <h4>Enhanced Livestock Health</h4>
              <p>
              AgroGenius's AI-based livestock monitoring helps farmers closely monitor the health and behavior of their animals. By detecting early signs of illness or distress, farmers can provide timely interventions, resulting in improved livestock health and reduced mortality rates.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some6} style={{ marginBottom: "15px" }} />
              <h4>Improved Resource Efficiency</h4>
              <p>
              AgroGenius optimizes resource utilization by providing intelligent recommendations on irrigation, fertilization, and pest control. By ensuring precise and targeted resource application, farmers can minimize wastage, reduce costs, and promote sustainable farming practices.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some3} style={{ marginBottom: "15px" }} />
              <h4>Cost Savings</h4>
              <p>
              AgroGenius's AI features help farmers reduce costs associated with crop management, livestock health, and resource utilization. By optimizing inputs and interventions, farmers can minimize unnecessary expenses and improve overall profitability
              </p>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            className="col-md-6 col-sm-12 "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some4} style={{ marginBottom: "15px" }} />
              <h4>Enhanced Disease and Pest Management</h4>
              <p>
              AgroGenius's AI algorithms enable early detection and effective management of crop diseases and pests. By identifying and addressing issues promptly, farmers can prevent the spread of diseases, reduce crop losses, and minimize the need for chemical interventions
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="cardnew2">
              <img src={Some5} style={{ marginBottom: "15px" }} />
              <h4>Data-Driven Decision Making</h4>
              <p>
              AgroGenius collects and analyzes a wide range of agricultural data, providing farmers with valuable insights for decision making. By leveraging data-driven recommendations, farmers can make informed choices about crop selection etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    


      <Flag/>
      <NewFaq/>
    </div>
  );
}

export default Solution5;
