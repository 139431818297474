import React from 'react'
import "../Hearder.css";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import Software from "../Assets/Frame.png";
import InterLogo from "../Assets/ecomlogo.png";
import Two from "../Assets/two.png";
import Three from "../Assets/three.png";
import Four from "../Assets/four.png";
import Five from "../Assets/five.png";
import Six from "../Assets/six.png";
import Seven from "../Assets/seven.png";
import Eight from "../Assets/eight.png";
import Nine from "../Assets/nine.png";
import Ten from "../Assets/ten.png";
import Webdev from "../Assets/webdev.png";
import Appdev from "../Assets/appdev.png";
import Interprise from "../Assets/interprise.png";
import Software1 from "../Assets/Frame.png";
import Contant from "../Assets/contant.png";
import Consultant from "../Assets/consultant.png";
import Social from "../Assets/social.png";
import Automation from "../Assets/automation.png";
import Creative from "../Assets/creative.png";
import AIML from "../Assets/aiml.png";
const Whattodomobile = () => {
    var syed1 = () => {
        const btm1= document.getElementById("i");
       btm1.style.display="block";
        const btm2= document.getElementById("ii");
        btm2.style.display="none";
        const btm3= document.getElementById("iii");
        btm3.style.display="none";   
        const btm4= document.getElementById("iv");
       btm4.style.display="none";   
        const btm5= document.getElementById("v");
        btm5.style.display="none";
        const btm6= document.getElementById("vi");
        btm6.style.display="none";
        const btm7= document.getElementById("vii");
        btm7.style.display="none";
        const btm8= document.getElementById("viii");
        btm8.style.display="none";   
        const btm9= document.getElementById("ix");
        btm9.style.display="none";   
        const btm10= document.getElementById("x");
        btm10.style.display="none";
      
      };
      var syed2 = () => {
        const btm1= document.getElementById("i");
       btm1.style.display="none";
        const btm2= document.getElementById("ii");
        btm2.style.display="block";
        const btm3= document.getElementById("iii");
        btm3.style.display="none";   
        const btm4= document.getElementById("iv");
       btm4.style.display="none";   
        const btm5= document.getElementById("v");
        btm5.style.display="none";
        const btm6= document.getElementById("vi");
        btm6.style.display="none";
        const btm7= document.getElementById("vii");
        btm7.style.display="none";
        const btm8= document.getElementById("viii");
        btm8.style.display="none";   
        const btm9= document.getElementById("ix");
        btm9.style.display="none";   
        const btm10= document.getElementById("x");
        btm10.style.display="none";
      
      };
       var syed3 = () => {
        const btm1= document.getElementById("i");
       btm1.style.display="none";
        const btm2= document.getElementById("ii");
        btm2.style.display="none";
        const btm3= document.getElementById("iii");
        btm3.style.display="block";   
        const btm4= document.getElementById("iv");
       btm4.style.display="none";   
        const btm5= document.getElementById("v");
        btm5.style.display="none";
        const btm6= document.getElementById("vi");
        btm6.style.display="none";
        const btm7= document.getElementById("vii");
        btm7.style.display="none";
        const btm8= document.getElementById("viii");
        btm8.style.display="none";   
        const btm9= document.getElementById("ix");
        btm9.style.display="none";   
        const btm10= document.getElementById("x");
        btm10.style.display="none";
      
      };
      var syed4 = () => {
        const btm1= document.getElementById("i");
        btm1.style.display="none";
         const btm2= document.getElementById("ii");
         btm2.style.display="none";
         const btm3= document.getElementById("iii");
         btm3.style.display="none";   
         const btm4= document.getElementById("iv");
        btm4.style.display="block";   
         const btm5= document.getElementById("v");
         btm5.style.display="none";
         const btm6= document.getElementById("vi");
         btm6.style.display="none";
         const btm7= document.getElementById("vii");
         btm7.style.display="none";
         const btm8= document.getElementById("viii");
         btm8.style.display="none";   
         const btm9= document.getElementById("ix");
         btm9.style.display="none";   
         const btm10= document.getElementById("x");
         btm10.style.display="none";
       
      };
      var syed5 = () => {
        const btm1= document.getElementById("i");
        btm1.style.display="none";
         const btm2= document.getElementById("ii");
         btm2.style.display="none";
         const btm3= document.getElementById("iii");
         btm3.style.display="none";   
         const btm4= document.getElementById("iv");
        btm4.style.display="none";   
         const btm5= document.getElementById("v");
         btm5.style.display="block";
         const btm6= document.getElementById("vi");
         btm6.style.display="none";
         const btm7= document.getElementById("vii");
         btm7.style.display="none";
         const btm8= document.getElementById("viii");
         btm8.style.display="none";   
         const btm9= document.getElementById("ix");
         btm9.style.display="none";   
         const btm10= document.getElementById("x");
         btm10.style.display="none";
       
      };
      var syed6 = () => {
        const btm1= document.getElementById("i");
        btm1.style.display="none";
         const btm2= document.getElementById("ii");
         btm2.style.display="none";
         const btm3= document.getElementById("iii");
         btm3.style.display="none";   
         const btm4= document.getElementById("iv");
        btm4.style.display="none";   
         const btm5= document.getElementById("v");
         btm5.style.display="none";
         const btm6= document.getElementById("vi");
         btm6.style.display="block";
         const btm7= document.getElementById("vii");
         btm7.style.display="none";
         const btm8= document.getElementById("viii");
         btm8.style.display="none";   
         const btm9= document.getElementById("ix");
         btm9.style.display="none";   
         const btm10= document.getElementById("x");
         btm10.style.display="none";
       
      };
      var syed7 = () => {
        const btm1= document.getElementById("i");
        btm1.style.display="none";
         const btm2= document.getElementById("ii");
         btm2.style.display="none";
         const btm3= document.getElementById("iii");
         btm3.style.display="none";   
         const btm4= document.getElementById("iv");
        btm4.style.display="none";   
         const btm5= document.getElementById("v");
         btm5.style.display="none";
         const btm6= document.getElementById("vi");
         btm6.style.display="none";
         const btm7= document.getElementById("vii");
         btm7.style.display="block";
         const btm8= document.getElementById("viii");
         btm8.style.display="none";   
         const btm9= document.getElementById("ix");
         btm9.style.display="none";   
         const btm10= document.getElementById("x");
         btm10.style.display="none";
       
      };
      var syed8 = () => {
        const btm1= document.getElementById("i");
       btm1.style.display="none";
        const btm2= document.getElementById("ii");
        btm2.style.display="none";
        const btm3= document.getElementById("iii");
        btm3.style.display="none";   
        const btm4= document.getElementById("iv");
       btm4.style.display="none";   
        const btm5= document.getElementById("v");
        btm5.style.display="none";
        const btm6= document.getElementById("vi");
        btm6.style.display="none";
        const btm7= document.getElementById("vii");
        btm7.style.display="none";
        const btm8= document.getElementById("viii");
        btm8.style.display="block";   
        const btm9= document.getElementById("ix");
        btm9.style.display="none";   
        const btm10= document.getElementById("x");
        btm10.style.display="none";
      
      };
      var syed9 = () => {
        const btm1= document.getElementById("i");
        btm1.style.display="none";
         const btm2= document.getElementById("ii");
         btm2.style.display="none";
         const btm3= document.getElementById("iii");
         btm3.style.display="none";   
         const btm4= document.getElementById("iv");
        btm4.style.display="none";   
         const btm5= document.getElementById("v");
         btm5.style.display="none";
         const btm6= document.getElementById("vi");
         btm6.style.display="none";
         const btm7= document.getElementById("vii");
         btm7.style.display="none";
         const btm8= document.getElementById("viii");
         btm8.style.display="none";   
         const btm9= document.getElementById("ix");
         btm9.style.display="block";   
         const btm10= document.getElementById("x");
         btm10.style.display="none";
       
      };
      var syed10 = () => {
        const btm1= document.getElementById("i");
       btm1.style.display="none";
        const btm2= document.getElementById("ii");
        btm2.style.display="none";
        const btm3= document.getElementById("iii");
        btm3.style.display="none";   
        const btm4= document.getElementById("iv");
       btm4.style.display="none";   
        const btm5= document.getElementById("v");
        btm5.style.display="none";
        const btm6= document.getElementById("vi");
        btm6.style.display="none";
        const btm7= document.getElementById("vii");
        btm7.style.display="none";
        const btm8= document.getElementById("viii");
        btm8.style.display="none";   
        const btm9= document.getElementById("ix");
        btm9.style.display="none";   
        const btm10= document.getElementById("x");
        btm10.style.display="block";
      
      };
  return (
<>
<div>
    <div className=''>
    <div className="whatwedoBtn  " onClick={syed1}>
                  <img src={InterLogo} className="logowhat" />
                  <h4 className="orange">Website Development</h4>
                </div>
    </div>
    <div>
    <div className="col" id="i">
            <div className="container webgrad padd66 ">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#EC9B00'}}>Website Development</h3>
                  <p className='padd66 fontww' >
                  Web development services are the core of our business. We provide a wide range of professional services in the development of web 2.0 and intranet applications of any complexity. Our highly talented team endeavors to develop web applications that can become an invaluable asset for your company.

                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Web Design & Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Web Apps</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Portal Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Application Maintenance</li>
                      </div>
                      
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#EC9B00',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Webdev} className="navimg hidetab" />
                  </div>
                </div>
              </div>
            </div>
    </div>
    <div>
    <div className="whatwedoBtn1" onClick={syed2}>
                  <img src={Two} className="logowhat" />
                  <h4 className="orange">App Development</h4>
                </div>
    </div>
    <div>
    <div className="col hideOptions" id="ii" >
            <div className="container appgrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#6471D7'}} >Application Development</h3>
                  <p className='padd66 fontww' >
                  Develop, test, and deploy custom mobile apps using a single code for all devices, browsers, and operating systems. At Askari Tech, we provide the tools and technologies that enterprises need to demystify and accelerate mobile app development.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Cross-Platform Enterprise App Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile UI/UX Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Mobile POS Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Maintenance and Support</li>
                      </div>
                      
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#6471D7',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Appdev} className="navimg hidetab" />
                  </div>
                </div>
              </div>
            </div>
    </div>
    <div>
    <div className="whatwedoBtn2" onClick={syed3} >
                  <img src={Three} className="logowhat" />
                  <h4 className="orange">Enterprise</h4>
                </div>
    </div>
    <div>
    <div className="col hideOptions" id="iii">
            <div className="container entergrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#BF9752'}}>Enterprise</h3>
                  <p className='padd66 fontww'>
                  Create enterprise-level applications customized to your business’ complex challenges and requirements with high-performing cloud platforms. Alleviate common pain points and let users complete their tasks efficiently and easily without interdependency. Join hands with us to leverage speed, scalability, and security!
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Enterprise Application Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Mobility Services</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Digital Transformation Services</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Software Integration</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Legacy Application Integration</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#BF9752',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Interprise} className="navimg hidetab" />
                  </div>
                </div>
              </div>
            </div>
    </div>
    <div>
    <div className="whatwedoBtn3" onClick={syed4} >
                  <img src={Four} className="logowhat" />
                  <h4 className="orange  mobileres">Software Development</h4>
                </div>
    </div>
    <div>
    <div className="col hideOptions" id="iv">     <div className="container softwaregrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#6471D7'}}>Software Development</h3>
                  <p className='padd66 fontww'>
                  At Askari Technologies, we specialize in offering a comprehensive suite of top-notch software development services tailored to meet the unique needs of your business. Our experienced team of software engineers is dedicated to delivering cutting-edge solutions that will elevate your company to new heights.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Software Development</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Enterprise Software Development</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Application Maintenance & Support</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Quality Assurance & Testing</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Legacy Software Modernization</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#6471D7',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Software1} className="navimg hidetab" />
                  </div>
                </div>
              </div></div>
    </div>
    <div>
    <div className="whatwedoBtn4" onClick={syed5}>
                  <img src={Five} className="logowhat" />
                  <h4 className="orange mobileres">Content Management</h4>
                </div>
    </div>
    <div>
    <div className="col hideOptions" id="v">
            <div className="container contantgrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#20AF92'}}>Content Management</h3>
                  <p className='padd66 fontww'>
                  Take control of your customers across all channels and touchpoints with hyper-accelerated digitally enhanced customer relationships. Implement, optimize, and scale with an undisputed CRM system!
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>CRM Implementation</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Workflow and Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Entity Customization</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>CRM Migration and Support</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Request Resolution</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#20AF92',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Contant} className="navimg hidetab" />
                  </div>
                </div>
              </div>
            </div>
    </div>
   <div>
   <div className="whatwedoBtn5" onClick={syed6}>
                  <img src={Six} className="logowhat" />
                  <h4 className="orange">Creative</h4>
                </div>
   </div>
   <div>
   <div className="col hideOptions" id="vi">
            <div className="container creativegrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#4C7CE0'}}>Creative</h3>
                  <p className='padd66 fontww'>
                  At Askari Technologies, creativity is at the heart of what we do. Our exceptional creative services are tailored to make your brand stand out in a competitive market. Our team of talented designers and creative minds are passionate about turning ideas into captivating realities.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Graphic Design</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Branding & Identity</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Logo Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>User Interface (UI) Design</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Animation & Motion Graphics</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Video Production</li>
                      </div> <div className="uldiv">
                      <PanoramaFishEyeIcon className="circleicon" />{" "}
                      <li>Illustration</li>
                    </div>
                     <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Print Design</li>
                      </div>
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#4C7CE0',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Creative} className="navimg hidetab" />
                  </div>
                </div>
              </div>
              </div>
   </div>
   <div>
   <div className="whatwedoBtn6" onClick={syed7} >
                  <img src={Seven} className="logowhat" />
                  <h4 className="orange">Social Media Services</h4>
                </div>
   </div>
   <div>
   <div className="col hideOptions" id="vii">
              <div className="container socialgrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#C86485'}}>Social Media Services</h3>
                  <p className='padd66 fontww'>
                  Unlock the true potential of your brand's online presence with our cutting-edge social media services. At Askari Technologies, we understand the power of social media in shaping brand identities, driving engagement, and fostering customer loyalty. Our dedicated team of social media experts is well-versed in crafting innovative strategies tailored to your brand's unique needs and objectives.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Strategy</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Content Creation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Community Management</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Advertising</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Influencer Marketing</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#C86485',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Social} className="navimg hidetab" />
                  </div>
                </div>
              </div>
              </div>
   </div>
   <div>
   <div className="whatwedoBtn7" onClick={syed8} >
                  <img src={Eight} className="logowhat" />
                  <h4 className="orange">Automation Services</h4>
                </div>
</div>
<div>
<div className="col  hideOptions" id="viii">
              <div className="container autograd padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#FE776B'}}>Automation Services</h3>
                  <p className='padd66 fontww'>

                  At Askari Technologies, we harness the power of automation to transform businesses, homes, and industries, making them more efficient, intelligent, and sustainable. Embrace the future with our advanced automation services tailored to meet your specific needs.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Software Automation</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Hardware Automation</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>IoT Solutions</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Sensor Integration</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Home Automation</li>
                      </div>
                      <div className="uldiv">
                      <PanoramaFishEyeIcon className="circleicon" />{" "}
                      <li>Robotics and AI</li>
                    </div>
                    <div className="uldiv">
                    <PanoramaFishEyeIcon className="circleicon" />{" "}
                    <li>Cloud Automation</li>
                  </div>
                  <div className="uldiv">
                  <PanoramaFishEyeIcon className="circleicon" />{" "}
                  <li>Industrial Automation</li>
                </div>
                <div className="uldiv">
                <PanoramaFishEyeIcon className="circleicon" />{" "}
                <li>Manufacturing Automation</li>
              </div>
              <div className="uldiv">
              <PanoramaFishEyeIcon className="circleicon" />{" "}
              <li>Smart Energy Management</li>
            </div>
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#FE776B',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Automation} className="navimg hidetab" />
                  </div>
                </div>
              </div>
              </div>
</div>
<div>
<div className="whatwedoBtn8"  onClick={syed9}>
                  <img src={Nine} className="logowhat" />
                  <h4 className="orange">AI/ML Services</h4>
                </div>
</div>
<div>
<div className="col hideOptions" id="ix">
              <div className="container aigrad padd66">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#58B5D9'}}>AI/ML Services</h3>
                  <p className='padd66 fontww'>
                  Give your customers the best of humans and technology with AI and ML solutions. Leverage the power of sentiment analysis, predictive models, IoT-enabled systems, etc., to prevent risks, automate workflows, measure performance, and build better customer relations.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Custom Chatbot Development and Integration</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Social Media Sentiment Analysis</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Pattern Mining and Forecasts</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>AI-Powered Alarms</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Asset Management</li>
                      </div>
                     
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#58B5D9',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={AIML} className="navimg hidetab" />
                  </div>
                </div>
              </div>
              </div>
</div>
<div>
<div className="whatwedoBtn9" onClick={syed10} >
                  <img src={Ten} className="logowhat" />
                  <h4 className="orange">Consulting Services</h4>
                </div>
</div>
<div>
<div className="col hideOptions " id="x">
              <div className="container colgrad padd66 ">
                <div className="row divHeader">
                  <h3 className="backendHeading padd66 mobhead" style={{color:'#277796'}}>Consulting Services</h3>
                  <p className='padd66 fontww'>

                  Welcome to Askari Technologies, where we offer comprehensive consulting services tailored to meet the diverse needs of businesses worldwide. With expertise in IT Consulting and HR Consultancy, we empower organizations to thrive and achieve sustainable growth.
                  </p>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="">
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>IT Consulting</li>
                      </div>

                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Data Science and Analytics Consulting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Digital Marketing Consulting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Healthcare IT Consulting</li>
                      </div>
                      <div className="uldiv">
                        <PanoramaFishEyeIcon className="circleicon" />{" "}
                        <li>Education Technology Consulting</li>
                      </div>
                          
                    </ul>
                    <button style={{
                        height:'40px',
                        width:'160px',
                        border:'none',
                        backgroundColor:'#277796',
                        borderRadius:'5px',
                        color:'white',
                        float:'left',
                        margin:'20px'
                    }}>Explore More</button>
                  </div>
                  <div className="col">
                    <img src={Consultant} className="navimg hidetab" />
                  </div>
                </div>
              </div>
              </div>
</div>
</div>

</>

    )
}

export default Whattodomobile