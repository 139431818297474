import React from "react";
import "../Styles/Hire.css";

import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/uk.png";
import UAE from "../Assets/uae.png";
const Flag = () => {
  return (
    <>
      <div className="row flags flagnone" style={{paddingTop:'30px'}}>
        <div className="col-md-3 col-sm-4  " style={{ textAlign: "center" }}>
          <img
            src={America}
            className="coll"
            style={{ display: "inline", width: "38px" }}
          />
          <h4 className="flagT">United Kingdom</h4>
          <p className="para2">
            31 St Clements Mansions Lillie Road
            <br /> London SW6 7PG
          </p>
        </div>
        <div className="col-md-3  col-sm-4  " style={{ textAlign: "center" }}>
          <img src={Australia} className="coll" style={{ display: "inline" }} />
          <h4 className="flagT">Australia</h4>
          <p className="para2">Comming Soon</p>
        </div>
        <div className="col-md-3  col-sm-4" style={{ textAlign: "center" }}>
          <img src={Pakistan} className="coll" style={{ display: "inline" }} />
          <h4 className="flagT">Pakistan</h4>
          <p className="para2">
            C-20 Block 5 Gulshan-e-Iqbal,
            <br /> Karachi,Pakistan
          </p>
        </div>
        <div className="col-md-3  col-sm-4 " style={{ textAlign: "center" }}>
          <img src={UAE} className="coll" style={{ display: "inline" }} />
          <h4 className="flagT">UAE</h4>
          <p className="para2">Comming Soon</p>
        </div>
      </div>
    </>
  );
};

export default Flag;
