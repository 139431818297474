import React from "react";
import "../Styles/Solution.css";
import img1sol from "../Assets/sol/Group.png";
import sol4 from "../Assets/sol4pic.png";
import "bootstrap/dist/css/bootstrap.css";
import img3 from "../Assets/images/client_connect_app_img.png";
import img4 from "../Assets/images/client_keyboard_app.png";
import { Link } from "react-router-dom";
import Flag from "./Flag";
import Some from "../Assets/notedownsome.png";
import Some2 from "../Assets/some2.png";
import Some3 from "../Assets/some3.png";
import Some4 from "../Assets/some4.png";
import Some5 from "../Assets/some5.png";
import Some6 from "../Assets/some6.png";
import Card1 from "../Assets/cardimg1.png";
import Card2 from "../Assets/card2.png";
import Card3 from "../Assets/card3.png";
import Card4 from "../Assets/card4.png";
import Card5 from "../Assets/card5.png";
import Card6 from "../Assets/card6.png";
import Card7 from "../Assets/card7.png";
import Card8 from "../Assets/card8.png";
import Card9 from "../Assets/card9.png";
import NewFaq from "./NewFaq";
function Solution4() {
  return (
    <div style={{overflow:'hidden'}}>
      <div className="banner_Solution4">
        <div className="Solutionbanner1_section">
          <h2>Streamline Your Business Operations with Ease</h2>
          <p>
            Asaan ERP is an advanced and user-friendly Enterprise Resource
            Planning (ERP) software developed by our company. It offers a
            comprehensive suite of modules designed to automate and integrate
            key business processes, enabling organizations to streamline their
            operations, improve efficiency, and drive growth. With its intuitive
            interface, powerful features, and robust functionality.
          </p>
        </div>
      </div>
      <section className="main_body_soltions">
        <section className="section1_solution">
          <div className="section1_content">
            <h3 style={{ color: "#3D4DAE" }}>
              Empowering Efficient Business Operations with Ease)
            </h3>
            <p style={{ color: "#686868", fontSize: "14px" }}>
              Asaan ERP is an advanced and user-friendly Enterprise Resource
              Planning (ERP) software developed by our company. It offers a
              comprehensive suite of modules designed to automate and integrate
              key business processes, enabling organizations to streamline their
              operations, improve efficiency, and drive growth. With its
              intuitive interface, powerful features, and robust functionality,
              Asaan ERP provides businesses with a centralized platform to
              manage their finances, HR, sales, manufacturing, CRM, inventory,
              purchasing, project management, supply chain, quality management,
              and service management.
            </p>
          </div>
          <div className="section2_img">
            <img src={sol4} className="imgsizing_sol2" />
          </div>
        </section>
        <section className="section2_solution">
          <h2
            style={{
              color: "#3D4DAE",
              marginBottom: "40px",
              fontWeight: "bolder",
              marginTop:'70px'
            }}
          >
            Features of Business Operations with Ease
          </h2>
          <div className="card_container_solu2">
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Smart Workflow Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                With AI-driven workflow automation, Asaan ERP automates
                repetitive tasks, such as data entry, document processing,
                report generation, invoice processing, and repetitive manual
                operations. This integration increases operational efficiency,
                reduces errors, and frees up resources for more strategic
                activities.
              </p>
            </div>
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Predictive Maintenance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Asaan ERP utilizes AI algorithms to implement predictive
                maintenance capabilities. By analyzing data from the
                Manufacturing and Service Management modules, the system can
                predict equipment failures or maintenance needs, allowing
                businesses to schedule proactive maintenance activities,
                minimize downtime, and optimize maintenance costs.
              </p>
            </div>
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Resource Allocation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                The AI-powered resource allocation feature in Asaan ERP helps
                businesses optimize the allocation of their workforce and
                equipment. By considering factors such as employee skills,
                availability, project requirements, and equipment utilization,
                the system automatically suggests the most efficient allocation,
                ensuring optimal resource utilization and improved project
                outcomes.
              </p>
            </div>
          </div>
          <div className="card_container_solu2">
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Smart Workflow Automation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                With AI-driven workflow automation, Asaan ERP automates
                repetitive tasks, such as data entry, document processing,
                report generation, invoice processing, and repetitive manual
                operations. This integration increases operational efficiency,
                reduces errors, and frees up resources for more strategic
                activities.
              </p>
            </div>
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Predictive Maintenance
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                Asaan ERP utilizes AI algorithms to implement predictive
                maintenance capabilities. By analyzing data from the
                Manufacturing and Service Management modules, the system can
                predict equipment failures or maintenance needs, allowing
                businesses to schedule proactive maintenance activities,
                minimize downtime, and optimize maintenance costs.
              </p>
            </div>
            <div className="sub_card_sol1" style={{ height: "250px" }}>
              <h5 style={{ color: "#3D4DAE", fontSize: "14px" }}>
                Intelligent Resource Allocation
              </h5>
              <p
                style={{
                  color: "#686868",
                  fontSize: "12px",
                  marginLeft: "3px",
                  marginLeft: "3px",
                  textAlign: "center",
                }}
              >
                The AI-powered resource allocation feature in Asaan ERP helps
                businesses optimize the allocation of their workforce and
                equipment. By considering factors such as employee skills,
                availability, project requirements, and equipment utilization,
                the system automatically suggests the most efficient allocation,
                ensuring optimal resource utilization and improved project
                outcomes.
              </p>
            </div>
            <div className="btn_sol1" style={{marginBottom:'5%'}}>
              <Link to={"/Contact"}>
                <button className="btn_sub_col1">Request A Demo</button>
              </Link>
            </div>
          </div>
        </section>
      </section>

  
      <section
        style={{
          backgroundColor: "#F5F5F5",
          paddingTop: "0px",
          paddingBottom: "30px",
         
        }}
      className="sixcard2">
        <div className="container2 ppp" >
          <h3
            style={{
              color: "#3D4DAE",
              fontSize: "48px",
              fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
              marginLeft: "20px",
             paddingTop:'30px',
              marginBottom: "40px",
              marginTop: "10px",
            }}
          >
          Benefits of Business Operations with Ease
          </h3>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some} style={{ marginBottom: "15px" }} />
                <h4>Streamlined Order Processing</h4>
                <p>
                Asaan ERP provides a unified platform that integrates various modules, eliminating data silos and streamlining business operations. This leads to increased efficiency, reduced duplication of efforts, and improved cross-departmental collaboration.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some2} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Productivity</h4>
                <p>
                With automation and AI-driven features, Asaan ERP reduces manual tasks, allowing employees to focus on strategic activities that drive business growth. This leads to increased productivity and improved overall performance.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some6} style={{ marginBottom: "15px" }} />
                <h4>Enhanced Customer Satisfaction</h4>
                <p>
                With Asaan ERP's integrated CRM module, businesses can effectively manage customer relationships and deliver personalized experiences. This leads to improved customer satisfaction, increased customer retention, and strengthened loyalty.

                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some3} style={{ marginBottom: "15px" }} />
                <h4>Improved Decision-making</h4>
                <p>
                Asaan ERP's advanced analytics and AI-powered insights provide businesses with real-time visibility into their operations. This enables informed decision-making, helps identify trends and opportunities, and supports proactive management.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-md-6 col-sm-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some4} style={{ marginBottom: "15px" }} />
                <h4>Cost Savings</h4>
                <p>
                By automating processes, optimizing resource utilization, and improving inventory management, Asaan ERP helps reduce operational costs. It minimizes wastage, improves resource efficiency, and enables better financial planning and control.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="cardnew2">
                <img src={Some5} style={{ marginBottom: "15px" }} />
                <h4>Scalability and Flexibility</h4>
                <p>
                Asaan ERP is designed to scale with the growing needs of businesses. It offers flexibility to adapt to changing business requirements, allowing organizations to customize workflows, add or remove modules, and integrate with other systems as needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <div className=" d-none d-sm-block ">
        <div className="empowering_business mt-5 p-3 ">
          <div className="text-center ">
            <h3 className="text-askari-primary fw-bold">
              Asaan ERP - Web and Desktop Application
            </h3>
            <p className="mt-3" style={{ color: "white" }}>
              Asaan ERP is available as both a web-based and desktop
              application, providing flexibility and accessibility to users. The
              web-based application allows users to access the ERP system from
              any device with an internet connection, enabling remote work and
              collaboration. The desktop application offers offline
              capabilities, ensuring uninterrupted access to critical business
              information even in areas with limited or unstable internet
              connectivity. Asaan ERP empowers businesses to optimize their
              operations, streamline processes, and achieve sustainable growth.
              With its comprehensive modules, AI-driven features, and
              user-friendly interface, Asaan ERP is the ideal solution for
              businesses seeking to enhance efficiency, improve decision-making,
              and drive success in today's competitive market.
            </p>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
}

export default Solution4;
