
import React, { useState, useRef, useEffect } from 'react';
import '../Styles/newRangeSlider.css';

function NewSlider() {
  const [start1, setStart1] = useState(10);
  const [start2, setStart2] = useState(90);

  const thumb1Ref = useRef();
  const thumb2Ref = useRef();
  const trackRef = useRef();
  const rangeRef = useRef();

  const handleDragStart = (setStart, otherStart, isDraggingThumb1) => (event) => {
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;

    const updatePosition = (moveEvent) => {
      const moveClientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      let newLeft = ((moveClientX - trackRef.current.getBoundingClientRect().left) / trackRef.current.offsetWidth) * 100;
      if (newLeft < 0) newLeft = 0;
      if (isDraggingThumb1 && newLeft > otherStart) newLeft = otherStart;
      if (!isDraggingThumb1 && newLeft < otherStart) newLeft = otherStart;
      if (!isDraggingThumb1 && newLeft > 100) newLeft = 100;
      setStart(newLeft);
    };

    document.addEventListener('mousemove', updatePosition);
    document.addEventListener('touchmove', updatePosition);

    const stopDragging = () => {
      document.removeEventListener('mousemove', updatePosition);
      document.removeEventListener('touchmove', updatePosition);
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchend', stopDragging);
    };

    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('touchend', stopDragging);
  };

  useEffect(() => {
    thumb1Ref.current.style.left = `${start1}%`;
    thumb2Ref.current.style.left = `${start2}%`;
    rangeRef.current.style.left = `${start1}%`;
    rangeRef.current.style.width = `${start2 - start1}%`;
  }, [start1, start2]);

  return (
    <div >
    <div className="slider1">
      <div className="track1" ref={trackRef}>
        <div className="range1" ref={rangeRef}></div>
        <div className="value1" style={{left: '10%'}}>${Math.round((start1 * 1000 + 1000) / 1000) * 1000}</div>
        <div className="value1" style={{right: '10%'}}>${Math.round((start2 * 1000 + 1000) / 1000) * 1000}</div>
        <div id="thumb1" className="thumb1" ref={thumb1Ref} onMouseDown={handleDragStart(setStart1, start2, true)} onTouchStart={handleDragStart(setStart1, start2, true)}></div>
        <div id="thumb2" className="thumb1" ref={thumb2Ref} onMouseDown={handleDragStart(setStart2, start1, false)} onTouchStart={handleDragStart(setStart2, start1, false)}></div>
      </div>
      
    </div>
    <div className="row" >
    <div className="col cols1">
      <h5>100$</h5>
    </div>
    <div className="col cols2">
      <h5>100000$</h5>
    </div>
  </div>
    </div>
  );
}

export default NewSlider;

